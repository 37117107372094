import Layout from '../../components/Navigation/layout'
import * as React from "react";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {Col, Container, Row} from "react-bootstrap";
import * as style from './index.module.scss';
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import Loading from "../../components/Loading/Loading";
import TopSellerReworked from "../../components/TopSellerReworked/TopSellerReworked";

const SuchePage = () => {
    const [products, setProducts] = useState([]);
    const [searching, setSearching] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');

    const umlautMap = {
        '\u00dc': 'UE',
        '\u00c4': 'AE',
        '\u00d6': 'OE',
        '\u00fc': 'ue',
        '\u00e4': 'ae',
        '\u00f6': 'oe',
        '\u00df': 'ss',
    }

    useEffect(() => {
        const URLparams = new URLSearchParams(location.search);
        const query = URLparams.get("q");
        setSearchQuery(query);

        let params = {
            'filter[Hidden]': 0,
            'filter[VendorID:not]': 0,
            'filter[Draft]': 0,
            'filterAny[Title:PartialMatch]': encodeURIComponent(query),
        };

        Client.Raw
            .get('Product/', {params})
            .then((res) => {
                setProducts(res.data);
                setSearching(false);
            })
            .catch(handleApiError);
    }, [])

    // function replaceUmlaute(str) {
    //     return str
    //         .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
    //             const big = umlautMap[a.slice(0, 1)];
    //             return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    //         })
    //         .replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', "g"),
    //             (a) => umlautMap[a]
    //         );
    // }

    return <Layout pageTitle="Marktplatz">
        <Container className="pb-5 pt-5">
            <Row>
                <Col lg={12} xs={6} className={style.product}>
                    <h4>{products.length} Ergebnisse gefunden für: {searchQuery}</h4>
                </Col>
            </Row>
            {searching ?
                <Loading type={"border"} message={"Daten werden geladen"}/>
                :
                products ?
                    // <Row>
                    //     {products.splice(0, 14).map((product, index) => {
                    //         return (
                    //             <Col lg={4} xs={6} key={index} className={style.product}>
                    //                 <Link to={`/${product.CategoryURLTitle}/${product.URLTitle}`}
                    //                       className={style.link}>
                    //                     <img src={product.PreviewImage} className={style.productImage}/>
                    //                     <div className={style.content}>
                    //                         <h4> {product.CategoryTitle}</h4>
                    //                         <h2> {product.Title} </h2>
                    //                     </div>
                    //                 </Link>
                    //             </Col>
                    //         )
                    //     })}
                    // </Row>
                    <TopSellerReworked products={products}/>
                    :
                    <p>Leider keine Ergebnisse</p>
            }
        </Container>
    </Layout>;
}

export default SuchePage;
